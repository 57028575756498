import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { environment } from '../../environments/environment';
import { ChartType, StudentTransferStatus } from './enums';
import { Currency, InsertField } from './interfaces';
import { T } from './t';

export class Constants {
    public static debugMode = false; // show all items in the sidebar
    public static showNew = false; // show items that are under development !!!!!!should always be false before commiting!!!!!!!!!!!!

    public static domainServer: string = environment.domainServer;
    public static baseUrl: string = environment.apiUrl;
    public static apiBaseUrl: string = Constants.baseUrl + 'api/';
    public static authUrl: string = Constants.apiBaseUrl + 'auth/';
    public static noauthUrl: string = Constants.apiBaseUrl + 'noauth/';

    public static readonly termsOfServiceUrl: string = environment.brand.termsOfUseUrl;
    public static readonly privacyPolicyUrl: string = environment.brand.privacyPolicyUrl;
    public static readonly editContactUrl: string = `/${environment.localization.enquiriesUrl}/edit-contact`;
    public static readonly editStudentUrl: string = `/${environment.localization.enquiriesUrl}/edit-student`;

    public static readonly editEventUrl: string = '/events/edit-event';
    public static readonly baseOrgUrl: string = '/orgs';

    /**
     *  version is comprised out of 4 numbers.
     *  1: main number, e.g. will change if we go from beta to first real versoin 0 -> 1
     *  2: if we add major change
     *  3: new normal release
     *  4: hot fix during release
     */
    public static version = '2.6.1';
    /*buildversion*/
    public static build = '0152';
    public static showNotificationInMs = 5000;
    public static mbToBytes = 1048576;
    // minus 2 years, to cover schools which starting year month not in january ET-3914
    public static twoYears = 2;

    public static noItemSelected = 'Please Select...';
    public static keepExistingValue = 'Keep existing value';
    public static removeExistingValue = 'Remove existing value';
    public static replaceExistingValueWith = 'Replace existing value with...';
    public static noCountrySelected = 'Please Select Country';
    public static fieldRequired = 'This field is required.';
    public static filterAll = 'All';
    public static reservedBySystem = 'Item is reserved by the system';
    public static deleteWarning = 'All selected items, of the whole table will be deleted.';
    // eslint-disable-next-line max-len
    public static passwordErrorText =
        'Password needs to be at least 14 characters long and contain at least 1 uppercase letter, 1 lowercase letter and 1 number.';
    public static logoErrorText = 'Could not retrieve logo. Please upload the logo again.';
    public static schoolManagementSystem = 'Student Information System';
    public static noDataInTable = 'No data available in table';
    public static phoneErrorText = 'Only an optional + in the beginning is allowed and a maximum of 16 numbers, dashes or spaces';
    public static mergeWarningText = 'Please select at least one field each row in order to merge';
    public static incompleteFormText = 'Please make sure all required fields have been entered';
    public static partnerPasswordNotValid =
        'Password format should be at least 4 characters including one uppercase letter, one lowercase letter and one number.';
    public static partnerSecurityInfo =
        'For additional security, you may enter a custom password to access the file or one will be generated. Make a note of this as it cannot be retrieved.';
    public static bodyMessageErrorText = 'This field is required and max length is 65,535 characters';
    public static msgstripeWebhookDisabled = 'stripeWebhookDisabled';
    // Messages for when there are problems saving/submitting forms
    public static readonly formResponses = {
        previewMode: 'You can not submit in the preview',
        invalidRequiredFields: 'Please be sure and fill out all the required fields',
        acceptCodeOfConduct: _.template('Please review and agree to the "${ codeOfConductTitle }"'),
        verifyHuman: 'Please click the "I\'m not a robot" checkbox',
    };
    public static msgDuplicatePrimarySecondaryContact =
        'The contact information you provided for the Primary and Secondary contacts is identical. Please enter unique information for each contact.';
    public static msgDuplicateStudents = 'The student data contains duplicate full names.';
    public static msgSchedulingFailed = 'Failed scheduling/sending message';
    public static msgFormIsNotAvailable =
        'Thank you for your interest in our school. We are not currently taking applications at this time, however, please check again later or contact us directly.';

    public static displayOtherLabel = 'Display Other... in this list';
    public static otherLabel = 'Other...';
    public static duplicatingMsg = 'Duplicating...';

    public static virusWarning =
        'The uploaded files originate from an external source and cannot be guaranteed to be virus free. Please ensure your anti-virus software is fully up to date and set to check downloads';
    public static stripeWebhookSetupFailMessage = 'Stripe webhook unexpectedly failed';
    public static stripeWebhookNotEnabledMessage = 'Stripe webhook is not enabled for this school';
    public static stripeWebhookenabledMessage = 'Webhook is enabled for this school';
    public static defaultItemsShownInTable = 10;
    public static patternPhoneNumber = /^(?=(.{5,16})$) ?(\+ ?)?([\d][ -]?)*$/;
    public static passwordPatternNew = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{14,128}$/;
    public static passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    public static partnerPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,20}$/;
    public static digitsPattern = /^[\d]{0,10}$/;
    public static urlPattern = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/;
    public static timePattern = /Invalid date|^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/i;
    public static timePattern12h = /Invalid date|^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))?$/i;

    public static reCaptchaSiteKey = environment.reCaptchaSiteKey;
    public static reCaptchaV2SiteKey = environment.reCaptchaV2SiteKey;

    public static emailMinLength = 2;
    public static emailMaxLength = 60;
    public static length60 = 60;
    public static length50 = 50;
    public static googleTrackingIdMinLength = 5;
    public static googleTrackingIdMaxLength = 20;
    public static googleAnalyticsSecretKeyMinLen = 16;
    public static googleAnalyticsSecretMaxLen = 64;
    public static GTM_ANALYTICS = 'GTM';
    public static GA3_ANALYTICS = 'GA3';
    public static GA4_ANALYTICS = 'GA4';
    public static nameMaxLength = 40;
    public static synCodeMaxLength = 60;
    public static studentExternalIdsMaxLength = 36;
    public static citySuburbFieldMaxLength = 50;
    public static requiredSchoolNameMaxLength = 80;
    public static requiredListItemNameMaxLength = 150;
    public static addressFieldMaxLength = 255;
    public static requiredTextFieldMinLength = 1; // for all required text fields
    public static textFieldMaxLength = 65535; // max for all text fields
    public static notRequiredTextFieldMinLength = 1; // for addresses, countries ect. not required
    public static descriptionTextFieldMaxLength = 1000; // max length for description fields
    public static requiredStringFieldMaxLength = 255; // max length for string field (not text)
    public static postCodeMinLength = 4; // for post codes not required
    public static postCodeMaxLength = 10;
    public static maxWidth = 1500;
    public static maxHeight = 1500;
    public static maxLogoSize = this.mbToBytes * 2;
    public static almostFullMessageField = 9950;
    public static messageMaxLength = 10000;
    public static htmlContentMaxLength = 10000;
    public static defaultTimeDifferenceInS = 3600;
    public static maxBookingAttendees = 20;
    public static maxAllowedEventSubTour = 10000;
    public static maxGeographicFileSizeMb = 5;
    public static maxGeographicFileSizeBytes = this.mbToBytes * this.maxGeographicFileSizeMb;
    public static requiredMinNumber = 1;
    public static otherPopupHeight = 213;
    public static requiredEmailSubjectMaxLength = 150;
    public static requiredEmailTemplateNameMaxLength = 80;
    public static requiredDiscountQuestionMaxLength = 200;
    public static requiredDiscountMessageMinLength = 10;
    public static requiredDiscountMessageMaxLength = 500;

    public static transferCheckPeriod = 15000;

    public static durationStartingYear = 20;
    public static durationGraduationYear = 70;

    public static minInfiniteTableLength = 25;

    public static fieldDescriptionMaxLength = 30000;
    public static fieldSchemaWidgetTypeId = 'info';

    public static loginUIAppearTime = 700;

    public static NUMBER_FORMAT_TWO_DECIMALS = '1.2-2';

    public static booleanOptions: { value: boolean; name: string }[] = [
        { value: true, name: 'Yes' },
        { value: false, name: 'No' },
    ];

    private static ngbModalOptions: NgbModalOptions = { backdrop: 'static', keyboard: false };
    public static ngbModalSm: NgbModalOptions = _.assign({ size: 'sm' }, Constants.ngbModalOptions);
    public static ngbModalMd: NgbModalOptions = _.assign({ size: 'md' }, Constants.ngbModalOptions);
    public static ngbModalXl: NgbModalOptions = _.assign({ size: 'xl' }, Constants.ngbModalOptions);
    public static ngbModalLg: NgbModalOptions = _.assign({ size: 'lg' }, Constants.ngbModalOptions);

    public static matDialogSm = '300px';
    public static matDialogMd = '500px';
    public static matDialogLg = '800px';
    public static matDialogXl = '1140px';

    public static dateFormats = {
        year: 'YYYY',
        dayMonthYearSlashed: 'DD/MM/YYYY',
        dayMonthYearSlashedNew: 'dd/MM/yyyy',
        dateTimeUTC: 'YYYY-MM-DDTHH:mm:ssZ',
        hourMinutes: 'HH:mm',
        hourMinutes12h: 'h:mm A',
        time: 'HH:mm:ss',
        dayMonthYear: 'DD-MM-YYYY',
        shortMonth: 'MMM',
        monthDay: 'MMMM DD',
        dayMonth: 'DD MMMM',
        dayShortMonth: 'DD MMM',
        date: 'YYYY-MM-DD',
        dateTime: 'YYYY-MM-DD HH:mm:ss',
        dateTime12h: 'YYYY-MM-DD h:mm A',
        dateTimeSlahed: 'DD/MM/YYYY HH:mm',
        timeShort: 'hh:mm A',
        dayMonthYearSlashedTimeShort: 'DD/MM/YYYY hh:mm A',
        dayMonthYearUnderScored: 'yy_MM_DD',
        shortMonthYear: 'MMM, yyyy',
    };

    public static localeFormats = {
        dateDelimiter: 'dateDelimiter',
        dateDelimiterTime: 'dateDelimiterTime',
        dateDelimiterTimeShort: 'dateDelimiterTimeShort',
        dateTime: 'dateTime',
        dateTimeShort: 'dateTimeShort',
        date: 'date',
        longDate: 'longDate',
        longDateWithComma: 'longDateWithComma',
        shortDate: 'shortDate',
    };

    public static readonly UTCTimeCode = 'Etc/UTC';
    public static readonly locale = 'en-AU';

    public static timePickerSettings = {
        format: Constants.dateFormats.timeShort,
        stepping: 15,
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove',
        },
    };

    public static Currencies: Currency[] = [
        { countryName: 'Australia', currencySymbol: '$', currencyText: 'AUD' },
        { countryName: 'Canada', currencySymbol: '$', currencyText: 'CAN' },
        { countryName: 'New Zealand', currencySymbol: '$', currencyText: 'NZD' },
        { countryName: 'USA', currencySymbol: '$', currencyText: 'USD' },
    ];

    public static insertSubjectEventEmail: InsertField[] = [
        { text: 'Campus Name', value: ' < CAMPUS NAME >' },
        { text: 'Contact First Name', value: ' < CONTACT FIRST NAME >' },
        { text: 'Event Date', value: ' < EVENT DATE >' },
        { text: 'Event Name', value: ' < EVENT NAME >' },
        { text: 'Event Start Time', value: ' < EVENT START TIME >' },
        { text: 'Event End Time', value: ' < EVENT END TIME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
    ];

    public static insertSubjectPersonalTourEmail: InsertField[] = [
        { text: 'Appointment Type', value: ' < APPOINTMENT TYPE >' },
        { text: 'Appointment Title', value: ' < APPOINTMENT TITLE >' },
        { text: 'Contact First Name', value: ' < CONTACT FIRST NAME >' },
        { text: 'Date', value: ' < DATE >' },
        { text: 'Start Time', value: ' < START TIME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
        { text: 'Campus Name', value: ' < CAMPUS NAME >' },
    ];

    public static insertMessageEventEmail: InsertField[] = [
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'Event Date', value: '&nbsp;< EVENT DATE >' },
        { text: 'Event Name', value: '&nbsp;< EVENT NAME >' },
        { text: 'Event Location', value: '&nbsp;< EVENT LOCATION >' },
        { text: 'Event Start Time', value: '&nbsp;< EVENT START TIME >' },
        { text: 'Event End Time', value: '&nbsp;< EVENT END TIME >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Subtour Name Time', value: '&nbsp;< SUBTOUR NAME TIME >' },
    ];

    public static insertMessagePersonalTourEmail: InsertField[] = [
        { text: 'Appointment Type', value: ' < APPOINTMENT TYPE >' },
        { text: 'Appointment Title', value: '&nbsp;< APPOINTMENT TITLE >' },
        { text: 'Appointment Description', value: '&nbsp;< APPOINTMENT DESCRIPTION >' },
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Location', value: '&nbsp;< LOCATION >' },
        { text: 'Date', value: '&nbsp;< DATE >' },
        { text: 'Start Time', value: '&nbsp;< START TIME >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
    ];

    public static insertEmailSignature: InsertField[] = [
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'School Address', value: '&nbsp;< SCHOOL ADDRESS >' },
        { text: 'School City', value: '&nbsp;< SCHOOL CITY >' },
        { text: 'School State', value: '&nbsp;< SCHOOL STATE >' },
        { text: 'School Postcode', value: '&nbsp;< SCHOOL POSTCODE >' },
        { text: 'User Name', value: '&nbsp;< USER NAME >' },
        { text: 'User Title', value: '&nbsp;< USER TITLE >' },
        { text: 'User Email', value: '&nbsp;< USER EMAIL >' },
    ];

    public static insertSubjectEmailTemplate: InsertField[] = [
        { text: 'Campus Name', value: ' < CAMPUS NAME >' },
        { text: 'Contact Salutation', value: ' < CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: ' < CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: ' < CONTACT LAST NAME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
    ];

    public static insertSubjectAppPasscodeEmailTemplate: InsertField[] = [
        { text: 'Application Form Name', value: ' < APPLICATION FORM NAME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
    ];

    public static insertMessageAppPasscodeEmailTemplate: InsertField[] = [
        { text: 'Passcode', value: '&nbsp;< PASSCODE >' },
        { text: 'Application Form Name', value: ' < APPLICATION FORM NAME >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Code Expires In Minutes', value: '&nbsp;< CODE EXPIRES IN MINUTES >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
    ];

    public static insertSubjectAppConfirmationEmailTemplate: InsertField[] = [
        { text: 'Application Form Name', value: ' < APPLICATION FORM NAME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
    ];

    public static insertMessageAppConfirmationEmailTemplate: InsertField[] = [
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Student First Name', value: '&nbsp;< STUDENT FIRST NAME >' },
        { text: 'Student Last Name', value: '&nbsp;< STUDENT LAST NAME >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Application Id', value: '&nbsp;< APPLICATION ID >' },
        { text: 'Submitted Date Time', value: '&nbsp;< SUBMITTED DATE TIME >' },
        { text: 'App Request URL', value: '&nbsp;< APP REQUEST URL >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
    ];

    public static insertSubjectAppReminderEmailTemplate: InsertField[] = [
        { text: 'Application Form Name', value: ' < APPLICATION FORM NAME >' },
        { text: 'Student Name', value: ' < STUDENT NAME >' },
    ];

    public static insertMessageAppReminderEmailTemplate: InsertField[] = [
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Student First Name', value: '&nbsp;< STUDENT FIRST NAME >' },
        { text: 'Student Last Name', value: '&nbsp;< STUDENT LAST NAME >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Form Permalink', value: '&nbsp;< FORM PERMALINK >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
    ];

    public static insertMessageEmailTemplate: InsertField[] = [
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Student First Name', value: '&nbsp;< STUDENT FIRST NAME >' },
        { text: 'Student Last Name', value: '&nbsp;< STUDENT LAST NAME >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
    ];

    public static insertMessageApplicationsEmail: InsertField[] = [
        { text: 'Application ID', value: '&nbsp;< APPLICATION ID >' },
        { text: 'Application Submitted Date', value: '&nbsp;< APPLICATION SUBMITTED DATE >' },
        { text: 'Application Submitted Time', value: '&nbsp;< APPLICATION SUBMITTED TIME >' },
        { text: 'Student First Name', value: '&nbsp;< STUDENT FIRST NAME >' },
        { text: 'Student Last Name', value: '&nbsp;< STUDENT LAST NAME >' },
        { text: 'Starting Year', value: '&nbsp;< STARTING YEAR >' },
        { text: T.intake_year_level, value: T.intakeYearLevelTag },
        { text: 'Date of Birth', value: '&nbsp;< DATE OF BIRTH >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
    ];

    public static insertSubjectBulkEvent: InsertField[] = [
        { text: 'Campus Name', value: ' < CAMPUS NAME >' },
        { text: 'Contact Salutation', value: ' < CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: ' < CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: ' < CONTACT LAST NAME >' },
        { text: 'Event Date', value: ' < EVENT DATE >' },
        { text: 'Event Name', value: ' < EVENT NAME >' },
        { text: 'Event Location', value: ' < EVENT LOCATION >' },
        { text: 'Event Start Time', value: ' < EVENT START TIME >' },
        { text: 'Event End Time', value: ' < EVENT END TIME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
    ];

    public static insertMessageBulkEvent: InsertField[] = [
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'Event Date', value: '&nbsp;< EVENT DATE >' },
        { text: 'Event Name', value: '&nbsp;< EVENT NAME >' },
        { text: 'Event Location', value: '&nbsp;< EVENT LOCATION >' },
        { text: 'Event Start Time', value: '&nbsp;< EVENT START TIME >' },
        { text: 'Event End Time', value: '&nbsp;< EVENT END TIME >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Subtour Name Time', value: '&nbsp;< SUBTOUR NAME TIME >' },
    ];

    public static insertSubjectAppointments: InsertField[] = [
        { text: 'Contact Salutation', value: ' < CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: ' < CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: ' < CONTACT LAST NAME >' },
        { text: 'Appointment Type', value: ' < APPOINTMENT TYPE >' },
        { text: 'Date', value: ' < DATE >' },
        { text: 'Start Time', value: ' < START TIME >' },
        { text: 'School Name', value: ' < SCHOOL NAME >' },
        { text: 'Campus Name', value: ' < CAMPUS NAME >' },
    ];

    public static insertMessageAppointments: InsertField[] = [
        { text: 'Contact Salutation', value: '&nbsp;< CONTACT SALUTATION >' },
        { text: 'Contact First Name', value: '&nbsp;< CONTACT FIRST NAME >' },
        { text: 'Contact Last Name', value: '&nbsp;< CONTACT LAST NAME >' },
        { text: 'Appointment Type', value: '&nbsp;< APPOINTMENT TYPE >' },
        { text: 'Location', value: '&nbsp;< LOCATION >' },
        { text: 'Date', value: '&nbsp;< DATE >' },
        { text: 'Start Time', value: '&nbsp;< START TIME >' },
        { text: 'Email Signature', value: '&nbsp;< EMAIL SIGNATURE >' },
        { text: 'School Name', value: '&nbsp;< SCHOOL NAME >' },
        { text: 'Campus Name', value: '&nbsp;< CAMPUS NAME >' },
    ];

    public static mergeTagNames: string[] = [
        'Campus Name',
        'School Name',
        'Email Signature',
        'Contact Salutation',
        'Contact First Name',
        'Contact Last Name',
        'Contact Email Address',
        'Contact Phone Number',
    ];

    public static months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    public static pageSizeOptions: number[] = [5, 10, 25, 50];

    public static webFormFields = {
        eventCampusId: 'eventCampusId',
        eventId: 'eventId',
        description: 'description',
        salutationId: 'salutationId',
        firstName: 'firstName',
        lastName: 'lastName',
        relationshipId: 'relationshipId',
        email: 'email',
        mobile: 'mobile',
        homePhone: 'homePhone',
        address: 'address',
        sendProspectus: 'sendProspectus',
        sendConfirmationContact2: 'sendConfirmationContact2',
        sendProspectusContact2: 'sendProspectusContact2',
        dateOfBirth: 'dateOfBirth',
        genderId: 'genderId',
        campusId: 'campusId',
        startingYear: 'startingYear',
        schoolIntakeYearId: 'schoolIntakeYearId',
        currentSchoolId: 'currentSchoolId',
        currentSchoolYearId: 'currentSchoolYearId',
        boardingTypeId: 'boardingTypeId',
        isInternational: 'isInternational',
        countryOfOriginId: 'countryOfOriginId',
        isFirstVisit: 'isFirstVisit',
        hearAboutUsId: 'hearAboutUsId',
        totalAttendees: 'totalAttendees',
        message: 'message',
        displayConduct: 'displayConduct',
        leadSourceId: 'leadSourceId',
    };

    public static translationPrefix = {
        fd: 'FD', // Field Description
        fl: 'FL', // Field Label
    };

    public static schoolModules = {
        commsModule: { name: 'commsModule', title: 'Communications (Email & Mail)' },
        appModule: { name: 'appsModule', title: 'Application Module' },
    };

    public static ChartContextMenuOptionConstant = {
        excelType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        csvType: 'application/vnd.openxmlformats-officedocument.wordprocessingml;charset=UTF-8',
    };

    public static chartTypeSelectors = [
        {
            type: ChartType.Pie,
            name: 'Pie',
            icon: 'pie_chart',
        },
        {
            type: ChartType.StackedColumn,
            name: 'Stacked Column Chart',
            icon: 'stacked_bar_chart',
        },
        {
            type: ChartType.HorizontalBar,
            name: 'Horizontal Bar Chart',
            icon: 'format_align_left',
        },
        {
            type: ChartType.Column,
            name: 'Column Chart',
            icon: 'bar_chart',
        },
        {
            type: ChartType.LineArea,
            name: 'Line Area Chart',
            icon: 'area_chart',
        },
        {
            type: ChartType.Line,
            name: 'Line Chart',
            icon: 'multiline_chart',
        },
        {
            type: ChartType.HeatMap,
            name: 'Heat Map Chart',
            icon: 'widgets',
        },
    ];

    public static transferStatuses = [
        { id: StudentTransferStatus.Done, value: T.done },
        { id: StudentTransferStatus.Failed, value: T.failed },
        { id: StudentTransferStatus.InProgress, value: T.inProgress },
    ];

    public static yearlyComparisonRangeFilter = [
        { id: 1, value: 'Current Year', sequence: 1 },
        { id: 2, value: 'Current & Last Year', sequence: 2 },
        { id: 3, value: 'Current & Last 2 Years', sequence: 3 },
        { id: 6, value: 'Current & Last 5 Years', sequence: 4 },
        { id: 0, value: 'ALL Years', sequence: 5 },
    ];

    public static eventTimeFilter = [
        { id: 1, value: 'All', sequence: 1 },
        { id: 2, value: 'Upcoming', sequence: 2 },
        { id: 3, value: 'Past', sequence: 3 },
    ];

    public static tagsToTurnOffAutoComplete = ['select', 'textarea', 'input'];

    public static icons = {
        people: 'people',
        public: 'public',
        school: 'school',
        source: 'source',
        supervised_user: 'supervised_user_circle',
        info: 'info',
        check_box: 'check_box',
        filter_list: 'filter_list',
        multiline_chart: 'multiline_chart',
        groups: 'groups',
        error: 'error',
    };
}
