import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { ResearchStudent } from 'app/analytics/research/research-student';
import { ApplicationSummaryDoc, StudentApplicationSummaryInfo } from 'app/applications/interfaces/documents/app-summary-doc';
import { AdministrativeArea } from 'app/entities/administrative-area';
import { AppStudentMapping } from 'app/entities/app-student-mapping';
import { Country } from 'app/entities/country';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { LeadScore } from 'app/entities/lead-score';
import { Legend } from 'app/entities/local/legend';
import { Student } from 'app/entities/student';
import { StudentStatus } from 'app/entities/student-status';
import { Translation } from 'app/entities/translation';
import { YearLevel } from 'app/entities/year-level';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';
import Swal, { SweetAlertResult } from 'sweetalert2';
import * as tinymce from 'tinymce';
import * as urlParse from 'url-parse';
import * as XLSX from 'xlsx';

import { environment } from '../../environments/environment';
import { Campus } from '../entities/campus';
import { CurrentSchool } from '../entities/current-school';
import { Event } from '../entities/event';
import { ListItem } from '../entities/list-item';
import { PersonalTour } from '../entities/personal-tour';
import { School } from '../entities/school';
import { ISelectedItem } from '../entities/slected-items';
import { User } from '../entities/user';
import { UserInfo } from '../entities/userInfo';
import { DialogService } from '../services/dialog.service';
import { Constants } from './constants';
import { Sequence } from './dto/sequence';
import { HasFutureSiblings, LICode, ManagementSystemCode, PageLeaveReason } from './enums';
import { Month } from './enums/date';
import { MediaType } from './enums/media-type';
import { ContactClaim, IAddressOptions, IPersonalTour, ISchoolModule } from './interfaces';
import { GoogleTrackingInfo } from './interfaces/google-tracking-info';
import { UtmParams } from './interfaces/utm-params';
import { Keys } from './keys';
import { T as translation } from './t';

declare const $: any;

export enum LogLevel {
    log,
    warning,
    error,
    hide,
}

export function createRange({ minValue, maxValue }: { minValue: number; maxValue: number }): number[] {
    return _.range(minValue, maxValue + 1); // need to add 1, since the lodash range function goes up to but not including
}

export function getMaxDateOfBirth() {
    return moment().add(1, 'years').toDate();
}

export function notifyEmailSent(totalRecipients: number): void {
    const emailMessage = `${totalRecipients} email${totalRecipients === 1 ? ' has' : 's have'} been sent`;
    Utils.showSuccessNotification(emailMessage);
}

export function sendingMessage(totalRecipients: number): string {
    return `Sending email${totalRecipients === 1 ? '' : 's'}...`;
}

export class Utils {
    private static timeZoneRE: RegExp = /.*\+([0-9]{2}):([0-9]{2})/;

    public static getRandomNumber() {
        return Math.floor(Date.now() / 1000);
    }

    public static getLocalDateTimeFromUtc(
        dateTime: moment.Moment,
        timeZoneMinutes: number,
        outputFormat: string = Constants.dateFormats.date
    ): string {
        return dateTime.utcOffset(timeZoneMinutes).format(outputFormat); // TODO use moment for this and not some custom calculation
    }

    public static getUserInfoFromToken(): UserInfo {
        const token = Utils.getToken();
        const userData: string[] = token.split('.');
        const userInfoJson = JSON.parse(atob(userData[1])).claims as UserInfo; // cast json data
        return new UserInfo(
            userInfoJson.id,
            userInfoJson.role,
            userInfoJson.viewMode,
            userInfoJson.organisationId,
            userInfoJson.schoolId,
            userInfoJson.schoolUniqId,
            userInfoJson.campusId,
            userInfoJson.mainCampusId,
            userInfoJson.undecidedCampusId,
            userInfoJson.specificCampusId,
            userInfoJson.managementSystemId,
            userInfoJson.eventId,
            userInfoJson.locale,
            userInfoJson.canManageSysAdmins,
            userInfoJson.roles
        );
    }

    public static getContactInfoFromToken(): ContactClaim {
        const token = Utils.getToken();
        let contactClaim: ContactClaim = null;
        if (token) {
            const userData: string[] = token.split('.');
            contactClaim = JSON.parse(atob(userData[1])).claims as ContactClaim; // cast json data
        }
        return contactClaim;
    }

    public static isAuthorized(actor: UserInfo | null | undefined, allowedRoles: string[] | undefined): boolean {
        // empty means everyone can access, even unauthenticated users
        if (!allowedRoles?.length) {
            return true;
        }
        if (!actor) {
            return false;
        }
        // fallback to actor.role if roles is not defined
        const actorRoles = actor.roles ?? [actor.role];
        return actorRoles.some(role => allowedRoles.includes(role));
    }

    public static setPerPage(domId: string) {
        $(domId).on('length.dt', function (e, settings, len) {
            localStorage.setItem('pageSize', len.toString());
        });
    }

    public static getPageSize(): number {
        const pageSize = localStorage.getItem('pageSize') || Constants.defaultItemsShownInTable;
        return Number(pageSize);
    }

    public static setPageSize(size: number) {
        localStorage.setItem('pageSize', size.toString());
    }

    public static getToken(): string | null {
        try {
            return localStorage.getItem('token');
        } catch (e) {
            return null;
        }
    }

    public static setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public static logout(router: Router, url?: string): void {
        // If token is null, it would mean either the given token being expired or the first time login page is open.
        if (localStorage.getItem('token') != null && !url)
            Utils.showNotification('Your session has expired and you have automatically been logged out.', Colors.danger);
        localStorage.removeItem('token');
        Utils.resetSession();
        router.navigate([url || 'noAuth/login']);
    }

    public static refreshPage(router: Router, pageRoute: any[]): Promise<any> {
        return router.navigate(['/dashboard/sendback'], { replaceUrl: true }).then(() => {
            return router.navigate(pageRoute, { replaceUrl: true });
        });
    }

    /**
     * Custom function for notifications
     * @param msg
     * @param color color can be (danger|success|info|warning|rose|primary)
     * @param y (top | bottom)
     * @param x (left | center | right)
     */
    public static showNotification(msg: string = 'Unknown Error', color: string, y = 'top', x = 'center') {
        try {
            $.notify(
                {
                    icon: 'notifications',
                    message: msg,
                },
                {
                    type: color,
                    delay: Constants.showNotificationInMs,
                    placement: {
                        from: y,
                        align: x,
                    },
                    newest_on_top: true,
                    z_index: 1056,
                    template: `
                        <div class="alert alert-dismissible col-11 col-sm-3 alert alert-{0}" role="alert">
                            <span data-notify="message">{2}</span>
                            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    `,
                }
            );
        } catch (error) {
            // workaround for some cases when for example google tag manager messes with the jQuery library, and $.notify does not work
            console.error(error);
            alert(msg);
        }
    }

    public static log(logValue: string, logLevel: LogLevel) {
        switch (logLevel) {
            case LogLevel.log:
                console.log(logValue);
                break;
            case LogLevel.warning:
                console.warn(logValue);
                break;
            case LogLevel.error:
                console.error(logValue);
                break;
            case LogLevel.hide:
                break;
        }
    }

    public static showSuccessNotification(msg?: string) {
        Utils.showNotification(msg ? msg : 'Changes successfully saved', Colors.success);
    }

    /** get id from table row */
    public static getClickedRowInfo(event, jquery_self, table) {
        event.preventDefault();
        let $tr = null;
        if (jquery_self.closest('tr.child').prev().length) {
            $tr = jquery_self.closest('tr.child').prev();
        } else {
            $tr = jquery_self.closest('tr[role="row"]');
        }
        const tableRow = table.row($tr);
        const id = _.parseInt(tableRow.data()[1]);
        return { id: id, tableRow: tableRow };
    }

    /** delete item from table */
    public static deleteItem(event, jquery_self, table, url, httpService): Promise<any> {
        return Utils.deletedQuestion().then(result => {
            if (result && result.value) {
                const rowInfo = Utils.getClickedRowInfo(event, jquery_self, table);
                return httpService.getAuth(url + rowInfo.id).then(() => {
                    rowInfo.tableRow.remove().draw();
                    return this.deletedSuccessfully().then(() => {
                        return Promise.resolve(true);
                    });
                });
            } else {
                return Promise.resolve(false);
            }
        });
    }

    public static async confirmDelete(deleteFn: () => Promise<void>, htmlContent = '', deleteMessage?: string): Promise<boolean> {
        const result = await Utils.deletedQuestion(htmlContent);
        if (result && result.value) {
            await deleteFn();
            await this.deletedSuccessfully(deleteMessage);
            return true;
        }
        return false;
    }

    public static delete(url, id, httpService, deleteMessage?: string): Promise<boolean> {
        return Utils.confirmDelete(() => httpService.getAuth(url + id), '', deleteMessage);
    }

    public static TryParseNumbersFromArray(stringArray: string[], defaultValue = null): Array<number | null> {
        const returnArray: Array<number | null> = [];
        stringArray.forEach(str => {
            returnArray.push(Utils.TryParseNumber(str));
        });
        return returnArray;
    }

    public static TryParseNumber(str: string, defaultValue = null): number | null {
        let retValue = defaultValue;
        if (str !== null) {
            if (str.length > 0) {
                retValue = parseInt(str, 10);
            }
        }
        return retValue;
    }

    public static infoAlert(text: string): Promise<SweetAlertResult> {
        return Swal.fire({
            text,
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false,
            allowOutsideClick: false,
        });
    }

    public static confirmDownload(text: string, confirmButtonText: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: 'Download Ready!',
            text,
            type: 'success',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-cancel',
            confirmButtonText,
            buttonsStyling: false,
            allowOutsideClick: false,
        });
    }

    public static deletedSuccessfully(text?: string) {
        const defaultText = 'Your item(s) has been deleted.';
        return Swal.fire({
            title: 'Deleted!',
            text: text || defaultText, // Use provided text or the default text
            type: 'success',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false,
        });
    }

    /**
     * @param html start with br tag
     */
    public static deletedQuestion(html = '') {
        return Swal.fire({
            title: 'Are you sure?',
            html: `You won\'t be able to revert this!${html}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-delete',
            cancelButtonClass: 'btn btn-cancel',
            confirmButtonText: 'Yes, delete it!',
            buttonsStyling: false,
        });
    }

    public static multipleDeletedSuccess(text?: string) {
        const defaultText = 'Your item(s) has been deleted.';
        return Swal.fire({
            title: 'Deleted!',
            text: text || defaultText, // Use provided text or the default text
            type: 'success',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false,
        });
    }

    /**
     * @param html start with br tag
     */
    public static multipleDeletedQuestion(countItems: number, html = '') {
        const locale = this.getUserInfoFromToken().locale || environment.localization;
        return Swal.fire({
            title: 'Are you really sure?',
            html: `All ${countItems.toLocaleString(locale)} item(s) you selected will be deleted.${html}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-delete',
            cancelButtonClass: 'btn btn-cancel',
            confirmButtonText: 'Yes, delete item(s)!',
            buttonsStyling: false,
        });
    }

    public static unlinkQuestion() {
        return Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you wish to unlink this related contact?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-delete',
            cancelButtonClass: 'btn btn-cancel',
            confirmButtonText: 'Yes, unlink it!',
            buttonsStyling: false,
        });
    }

    public static mergeWarning(msg: string) {
        return Swal.fire({
            title: 'Unable to Merge',
            text: msg,
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            confirmButtonText: 'ok',
            buttonsStyling: false,
        });
    }

    public static unlinkedSuccess() {
        return Swal.fire({
            title: 'Unlinked!',
            text: 'Item has been unlinked.',
            type: 'success',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false,
        });
    }

    public static missingCodeDialog(isExport: boolean, missingFields: string[]) {
        const fieldNames: string[] = [];
        _.forEach(missingFields, i => {
            switch (i) {
                case Keys.schoolIntakeYear:
                    fieldNames.push('SeekingEnrolmentInYearLevel');
                    break;
                case Keys.studentStatus:
                    fieldNames.push('EnquiryStatus');
                    break;
                case Keys.leadSource:
                    fieldNames.push('EnquirySource');
                    break;
                case Keys.hearAboutUs:
                    fieldNames.push('HowDidYouHearAboutUs');
                    break;
                default:
                    fieldNames.push(_.upperFirst(i));
                    break;
            }
        });

        const str1 = isExport ? 'export' : 'download';
        const str2 = isExport
            ? 'As a result, you may not have all the necessary data when importing to your Student Information System'
            : 'If you decide to import this exported file you will lose the information for these fields';
        return Swal.fire({
            title: 'Notification!',
            html:
                'This ' +
                str1 +
                ' will not contain the corresponding codes for the following fields:<br><u><small>' +
                _.join(fieldNames, ',<br>') +
                '</small></u><br>' +
                str2 +
                '!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-cancel',
            confirmButtonText: 'Continue',
            buttonsStyling: false,
        });
    }

    public static clone(obj: Object) {
        return JSON.parse(JSON.stringify(obj));
    }

    public static getCurrentCampusTimeZoneId(campuses: Campus[], userCampusId: number = null) {
        const mainCampus: Campus = _.find(campuses, c => c.campusType === Campus.CAMPUS_TYPE_MAIN);
        const currentCampus: Campus = userCampusId === null ? mainCampus : _.find(campuses, c => c.id === userCampusId);
        return currentCampus.campusType === Campus.CAMPUS_TYPE_MAIN || currentCampus.campusType === Campus.CAMPUS_TYPE_UNDECIDED
            ? mainCampus.timeZoneId
            : currentCampus.timeZoneId;
    }

    public static canDeactivate(
        changed: number,
        submitted: boolean,
        formIsValid: boolean,
        msg = 'Are you sure you want to leave without saving changes?'
    ): Promise<number> {
        const dialogService: DialogService = new DialogService();
        if (changed < 1) {
            return Promise.resolve(PageLeaveReason.doNotSave);
        } else {
            if (submitted) {
                return Promise.resolve(PageLeaveReason.save);
            } else {
                return dialogService.showPageLeaveDialog(msg, formIsValid);
            }
        }
    }

    public static arrayZeroItemsToNull(array: number[]) {
        return _.map(array, i => (i === 0 ? null : i));
    }

    public static getStartingYears(earliestStartYear?: number): number[] {
        const startingYears: number[] = [];
        const currentYear = new Date().getFullYear();
        // always have range from earliest starting year in db to current year + 20
        const firstYear = earliestStartYear || currentYear;
        const lastYear = currentYear + Constants.durationStartingYear;
        for (let i = firstYear; i <= lastYear; i++) {
            startingYears.push(i);
        }
        return startingYears;
    }

    public static getStartingYear(startingMonth: Month): number {
        return Utils.getNextStartingYear(startingMonth) - 1;
    }

    public static getNextStartingYear(startingMonth: Month): number {
        const currentDate = new Date();
        return currentDate.getMonth() >= startingMonth ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    }

    public static getCurrentAndPastYears(durationYears: number, earliestStartYear?: number): number[] {
        const years: number[] = [];
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - durationYears;
        const firstYear = earliestStartYear != null && earliestStartYear < currentYear ? earliestStartYear : currentYear;
        for (let i = firstYear; i >= lastYear; i--) {
            years.push(i);
        }
        return years;
    }

    public static getBaseUrl() {
        return location.origin;
    }

    // eslint-disable-next-line
    //https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
    public static inIframe(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    public static getFormSubmissionMessage(msg = ''): string {
        let formSubmissionMessage = msg || 'Thank you, your information has been successfully submitted.';
        console.log(`submitted successfully isIos: ${Utils.isIosMobile()} inIframe:${Utils.inIframe()}`);
        if (Utils.isIosMobile() && !Utils.inIframe()) {
            formSubmissionMessage = formSubmissionMessage + ' You can close this tab now.';
        }
        return formSubmissionMessage;
    }

    public static getIframe(widgetType: number, widgetId: number, uniqId: string, formId?: string) {
        const formIdParam = formId ? ` data-form-id="${formId}"` : '';
        const htmlCode = `<!-- ${environment.brand.name} Form Code. Paste the below div where you want the form (or button) to appear -->
<div class="et-widget" data-widget-type="${widgetType}" data-widget-id="${widgetId}"${formIdParam}></div>

<!-- ${environment.brand.name} Form Code. Paste the below code at the end of your page, typically as part of your footer -->
<script>
    (function (document) {
        var loader = function () {
            fetch('${Utils.getBaseUrl()}/api/noAuth/widget/getWidgetScriptForSchool/${uniqId}', {
                headers: {
                    Accept: '${MediaType.html}',
                }
            })
            .then(response => response.text())
            .then(scriptText => {
                const script = document.createElement('script'), tag = document.getElementsByTagName('script')[0];
                tag.parentNode.insertBefore(script, tag);
                script.text = scriptText;
                script.onerror= function() {
                    etWidgetDivs = document.getElementsByClassName('et-widget');
                    var testDivs = Array.prototype.filter.call(etWidgetDivs, function(etWidgetDiv) {
                        etWidgetDiv.innerHTML = '<p><span style="color:red">${translation.underMaintenanceMsgForForms}</span></p>';
                    });
                }
            })
        };
        document.addEventListener("DOMContentLoaded", loader);
    })(document);
</script>`;
        return htmlCode;
    }

    public static DetectChanges(ref: ChangeDetectorRef) {
        if (!ref['destroyed']) {
            Utils.log('detecting changes, in zone:' + NgZone.isInAngularZone(), LogLevel.hide);
            ref.detectChanges();
        }
    }

    public static focusOnSearch(htmlId) {
        $(document).on('shown.bs.modal', '#' + htmlId, () => {
            $('#full-width-filter input.search-field').focus();
        });
    }

    public static isInZone(marker: string = '') {
        console.log(marker + ': in zone:' + NgZone.isInAngularZone());
    }

    public static resetSession() {
        sessionStorage.clear();
    }

    public static destroyTinyMCE(id: string) {
        Utils.log('dispose of tinymce', LogLevel.hide);
        // https://stackoverflow.com/questions/17759111/tinymce-4-remove-or-destroy
        tinymce.remove();
        tinymce.execCommand('mceRemoveControl', true, id);
    }

    public static disposeModal(id: string) {
        Utils.log('disposing modal' + id, LogLevel.hide);
        $(id).modal('dispose');
    }

    public static reverseAddress(a) {
        if ($.trim(a) !== '') {
            const address = a.split(', ');
            const city = $.trim(address[0]);
            const state = $.trim(address[1]);
            return state + ', ' + city;
        }
        return '';
    }

    public static replaceTag(str: string, tag: string, text: string) {
        return str.replace(new RegExp('\\' + tag, 'gm'), text);
    }

    public static replaceEmailSignatureTag(kit: string, user: User, school: School) {
        kit = Utils.replaceTag(kit, '&lt; EMAIL SIGNATURE &gt;', school.emailSignature.signature);
        kit = Utils.replaceTag(kit, '&lt; USER NAME &gt;', `${user?.firstName ?? ''} ${user?.lastName ?? ''}`);
        kit = Utils.replaceTag(kit, '&lt; USER TITLE &gt;', user?.title ?? '');
        kit = Utils.replaceTag(kit, '&lt; USER EMAIL &gt;', user?.email ?? '');
        if (school) {
            kit = Utils.replaceTag(kit, '&lt; SCHOOL NAME &gt;', school?.name ?? '');
            kit = Utils.replaceTag(kit, '&lt; SCHOOL ADDRESS &gt;', school?.address ?? '');
            kit = Utils.replaceTag(kit, '&lt; SCHOOL CITY &gt;', school?.city ?? '');
            kit = Utils.replaceTag(kit, '&lt; SCHOOL STATE &gt;', school?.administrativeArea?.name ?? '');
            kit = Utils.replaceTag(kit, '&lt; SCHOOL POSTCODE &gt;', school?.postCode ?? '');
        }
        return kit;
    }

    // Functions for Table
    public static createTable(head, rows, caption) {
        return (
            '<div class="table-responsive"><table class="table table-sm">' +
            this.addElement(caption, 'caption') +
            this.addElement(head, 'thead') +
            this.addElement(rows, 'tbody') +
            '</table></div>'
        );
    }

    public static addTableHead(headRow: string[]) {
        let cols = '';
        _.forEach(headRow, h => {
            cols += this.addElement(h, 'th');
        });
        return this.addElement(cols, 'tr');
    }

    public static addTableRow(rowColumns: string[]) {
        let cols = '';
        _.forEach(rowColumns, c => {
            cols += this.addElement(c, 'td');
        });
        return this.addElement(cols, 'tr');
    }

    private static addElement(value: string, tagName: string) {
        return '<' + tagName + '>' + value + '</' + tagName + '>';
    }

    public static logConditionally(doLog: boolean, log: string) {
        if (doLog) {
            console.log(log);
        }
    }

    /**
     * Note that for events (and appointments) the events is considered a future event
     * only if it starts on the next day or later (in the time zone of the campus).
     * @param eventOrPersonalTour event or personalTour with local date and time in the campus time zone
     * @param timeZoneId time zone of the campus
     */
    public static isFutureEventOrPersonalTour(eventOrPersonalTour: Event | PersonalTour | IPersonalTour, timeZoneId: string): boolean {
        return !this.isPastEventOrPersonalTour(eventOrPersonalTour, timeZoneId);
    }

    /**
     * Note that for events (and appointments) the events is considered a past event
     * only when the day has passed (where the day ends at 24:00 in the time zone of the campus).
     * @param eventPersonalTour event or personalTour with local date and time in the campus time zone
     * @param timeZoneId time zone of the campus
     */
    public static isPastEventOrPersonalTour(eventOrPersonalTour: Event | PersonalTour | IPersonalTour, timeZoneId: string): boolean {
        const endOfEventDay = moment.tz(eventOrPersonalTour.date, timeZoneId).endOf('day');
        return endOfEventDay.isBefore();
    }

    public static filterFutureEventPersonalTours(
        eventsPersonalTours: Array<Event | PersonalTour | IPersonalTour>,
        campuses: Campus[]
    ): Array<Event | PersonalTour | IPersonalTour> {
        return _.filter(eventsPersonalTours, (eventOrPersonalTour: Event | PersonalTour | IPersonalTour) => {
            const timeZoneId: string = _.find(campuses, (campus: Campus) => campus.id === eventOrPersonalTour.campusId).timeZoneId;
            const isFuture = this.isFutureEventOrPersonalTour(eventOrPersonalTour, timeZoneId);
            eventOrPersonalTour.isFuture = isFuture;
            return isFuture;
        });
    }

    public static getGoogleTagManagerHeader(googleTrackingId: string) {
        return (
            `
            <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '` +
            googleTrackingId +
            `');</script>
        `
        );
    }

    public static getGoogleTagManagerBody(googleTrackingId: string) {
        return (
            `
            <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=` +
            googleTrackingId +
            `" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            </noscript>
        `
        );
    }

    public static embedGoogleTracking(dataLayer: any, googleTrackingInfo: GoogleTrackingInfo): void {
        if (googleTrackingInfo.googleTrackingIsEnabled && googleTrackingInfo.googleTrackingId?.startsWith('GTM')) {
            dataLayer = window['dataLayer'] = window['dataLayer'] || [];
            $('head').append(Utils.getGoogleTagManagerHeader(googleTrackingInfo.googleTrackingId));
            $('body').prepend(Utils.getGoogleTagManagerBody(googleTrackingInfo.googleTrackingId));
        }
    }

    public static triggerGoogleAnalytics(dataLayer: any, googleTrackingInfo: GoogleTrackingInfo) {
        if (dataLayer && googleTrackingInfo.googleTrackingIsEnabled && googleTrackingInfo.googleTrackingId.startsWith('GTM')) {
            dataLayer.push({ event: googleTrackingInfo.googleTrackingEventName });
        }
    }

    public static getIncludedInList(listItems: ListItem[], selectedIds: number[]) {
        let filteredListItems: ListItem[] = [];
        if (_.isArray(listItems) && listItems.length > 0) {
            filteredListItems = _.filter(listItems, i => i.includeInList || _.includes(selectedIds, i.id));
            if (listItems[0].list && listItems[0].list.listSetting && listItems[0].list.listSetting.displayOther) {
                filteredListItems.push(ListItem.getListItemOther());
            }
        }
        return filteredListItems;
    }

    public static getIncludedInListCurrentSchools(currentSchools: CurrentSchool[], selectedIds: number[], showDisplayOther: boolean) {
        let filteredCurrentSchools: CurrentSchool[] = [];
        if (_.isArray(currentSchools) && currentSchools.length > 0) {
            filteredCurrentSchools = _.filter(currentSchools, i => i.includeInList || _.includes(selectedIds, i.id));
        }
        if (showDisplayOther) {
            const other: CurrentSchool = new CurrentSchool();
            other.id = 0;
            other.schoolName = Constants.otherLabel;
            filteredCurrentSchools.push(other);
        }
        return filteredCurrentSchools;
    }

    /**
     * Removes items with id less the 0 (custom user items)
     * @param arrays Array<Array<ListItem | CurrentSchool>>
     */
    public static removeCustomItems(arrays: Array<Array<ListItem | CurrentSchool>>) {
        _.forEach(arrays, array => {
            _.remove(array, i => i.id < 0);
        });
    }

    public static selectItem(id: number, isChecked: boolean, selectedIds: number[]) {
        if (isChecked) {
            selectedIds.push(id);
        } else {
            _.pull(selectedIds, id);
        }
        return selectedIds;
    }

    public static selectAll<T extends ISelectedItem>(isChecked: boolean, items: T[], selectedIds: number[]) {
        _(items).forEach((item: T) => {
            item.selected = isChecked;
        });
        if (isChecked) {
            _.remove(selectedIds);
            _(items).forEach((item: T) => {
                selectedIds.push(item.id);
            });
        } else {
            _.remove(selectedIds);
        }
        return { items, selectedIds };
    }

    public static getElementOffsetTop(element) {
        return element.source._elementRef.nativeElement.getBoundingClientRect().top;
    }

    public static formatDate(formValues: any) {
        // strip the local timezone info, we just want raw
        return moment(formValues).format(Constants.dateFormats.date);
    }

    /* ios iphone/ipad cannot handle iframes without jumping, this solution should help a little bit
       https://stackoverflow.com/questions/34766636/ios-browser-iframe-jumps-to-top-when-changing-css-or-content-using-javascript
    */
    public static prepareIframeIos() {
        const isIosMobile = Utils.isIosMobile();
        const body: any = document.getElementsByTagName('BODY')[0];
        const html: any = document.getElementsByTagName('HTML')[0];
        if (isIosMobile) {
            const customCss = 'height: 100vh; overflow: auto; -webkit-overflow-scrolling:auto;';
            body.setAttribute('style', customCss);
            html.setAttribute('style', customCss);
        }
    }

    public static isIosMobile() {
        const userAgent = navigator.userAgent;
        const isIphone = userAgent.indexOf('iPhone') !== -1;
        const isIpod = userAgent.indexOf('iPod') !== -1;
        const isIpad = userAgent.indexOf('iPad') !== -1;

        // now set one variable for all iOS devices
        const isIosMobile = isIphone || isIpod || isIpad;
        return isIosMobile;
    }

    public static onElementHeightChange(element: any, idOnPage: string) {
        let lastHeight = 0,
            newHeight;
        (function run() {
            newHeight = element.clientHeight;
            if (lastHeight !== newHeight && newHeight !== 0) {
                const message = { name: 'resizeIframe', params: { height: newHeight, widgetId: idOnPage } };
                window.parent.postMessage(message, '*');
                // console.log('posted iframe message: ')
                // console.log(message)
            }
            lastHeight = newHeight;
            if (element.onElementHeightChangeTimer) {
                clearTimeout(element.onElementHeightChangeTimer);
            }
            if (Utils.isIosMobile()) {
                // since ios does not seem to handle
                if (newHeight === 0) {
                    element.onElementHeightChangeTimer = setTimeout(run, 200);
                }
            } else {
                element.onElementHeightChangeTimer = setTimeout(run, 200);
            }
        })();
    }

    public static getLocalTimeFromUtc(dateTimeUTC: string, inputFormat: string | null, timeZone: string, outputFormat: string): string {
        return Utils.convertTime(dateTimeUTC, inputFormat, Constants.UTCTimeCode, timeZone, outputFormat);
    }

    public static getUtcTimeFromLocal(dateTime: string, inputFormat: string | null, timeZone: string, outputFormat: string): string {
        return Utils.convertTime(dateTime, inputFormat, timeZone, Constants.UTCTimeCode, outputFormat);
    }

    public static convertTimeOutputMoment(
        dateTime: string,
        inputFormat: string | null,
        inputTimeZoneString: string,
        outputTimeZoneString: string
    ): moment.Moment {
        // console.log('converting dateTime input:' + dateTime + " inputTimeZoneString: " + inputTimeZoneString +
        // " outputTimeZoneString: " + outputTimeZoneString);
        let myMoment: moment.Moment | null = null;

        if (inputFormat != null) {
            myMoment = moment.tz(dateTime, inputTimeZoneString);
        } else {
            myMoment = moment.tz(dateTime, inputFormat, inputTimeZoneString);
        }
        const convertedMoment: moment.Moment = myMoment.clone().tz(outputTimeZoneString);
        return convertedMoment;
    }

    public static convertTime(
        dateTime: string,
        inputFormat: string | null,
        inputTimeZoneString: string,
        outputTimeZoneString: string,
        outputFormat: string
    ): string {
        const myMoment: moment.Moment = Utils.convertTimeOutputMoment(dateTime, inputFormat, inputTimeZoneString, outputTimeZoneString);
        const output: string = myMoment.format(outputFormat);

        return output;
    }

    /**
     * Extracts the date out of a date time in the timezone of the browser
     * @param dateTime  Date object or parseable date string
     * @returns         String date with YYYY-MM-DD format or null when input is not a valid date
     */
    public static getDateOnly(dateTime: Date | string): string {
        const mDateTime = moment(dateTime);
        return mDateTime.isValid() ? mDateTime.format(Constants.dateFormats.date) : null;
    }

    public static export2Csv(data: string, fileName: string) {
        const blob = new Blob([data], { type: MediaType.csv });
        return saveAs(blob, _.replace(fileName, / /g, '_') + '.csv');
    }

    public static saveBlobAsFile(data: Blob, fileName: string, type: MediaType) {
        const blob = new Blob([data], { type });
        return saveAs(blob, fileName);
    }

    /**
     * @param startTime - a non-null start time
     * @param endTime - the end time or `null`
     * @returns True if start time is before end time or end time is null
     */
    public static startTimeIsBeforeEndTime(startTime: string, endTime: string) {
        if (!endTime) {
            return true;
        }
        return moment(startTime, Constants.dateFormats.hourMinutes12h).isBefore(moment(endTime, Constants.dateFormats.hourMinutes12h));
    }

    public static isMobileSize() {
        return $(window).width() > 991 ? false : true;
    }

    public static createSortCaseInsensitiveMatTable<T>(data: T[]): MatTableDataSource<T> {
        const dataSource = new MatTableDataSource<T>(data);
        dataSource.sortingDataAccessor = (item, sortHeaderId) => _.toLower(_.get(item, sortHeaderId));
        return dataSource;
    }

    public static getTranslation(translations: Translation[], prefix: string, id: string, subCategory: string, category: string): string {
        return (
            _.find(translations, tr => tr.id === prefix + '_' + id && tr.subCategory === subCategory && tr.category === category)
                .translation || ''
        );
    }

    public static getUnixTimestamp(dateTime: string, inputFormat: string = Constants.dateFormats.dateTime): number {
        const momentDataTime = dateTime ? moment(dateTime, inputFormat) : null;
        return momentDataTime && momentDataTime.isValid ? momentDataTime.unix() : 0;
    }

    public static getEnumAsArray(givenEnum) {
        return _.keys(givenEnum)
            .filter(key => !isNaN(+key))
            .map(key => ({ id: +key, name: givenEnum[+key] }));
    }

    public static getEnumValues(givenEnum) {
        return _.keys(givenEnum)
            .filter(key => !isNaN(+key))
            .map(key => givenEnum[key]);
    }

    public static isMac(): boolean {
        return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    }

    public static getStandardOffset(zone: string) {
        // start with now
        const m = moment.tz(zone);
        // advance until it is not DST
        while (m.isDST()) {
            m.add(1, 'month');
        }
        // return the formatted offset
        return m.format('Z');
    }

    public static getEndTime(startTime, difference) {
        const startTimePlusOne = moment(startTime, Constants.dateFormats.hourMinutes12h).add(difference, 'seconds');
        const endTime = moment(startTimePlusOne, Constants.dateFormats.time).format(Constants.dateFormats.hourMinutes12h);
        return endTime;
    }

    public static getTimeDifferenceInS(startTime: string, endTime: string) {
        let startTimeMoment = moment(startTime, Constants.dateFormats.hourMinutes12h);
        const endTimeMoment = moment(endTime, Constants.dateFormats.hourMinutes12h);
        if (!startTime) {
            startTimeMoment = endTimeMoment.clone();
            startTimeMoment.subtract('seconds', Constants.defaultTimeDifferenceInS);
        }
        return endTimeMoment.diff(startTimeMoment, 'seconds');
    }

    public static getAppsYearLevelList(applications: StudentApplicationSummaryInfo[]): number[] {
        return _(applications)
            .map(s => s.intakeYearLevelId)
            .uniq()
            .value();
    }
    public static getAppsStartingYearList(applications: StudentApplicationSummaryInfo[]): number[] {
        return _(applications)
            .map(s => s.startingYear)
            .uniq()
            .value();
    }

    public static getAppsFormList(applications: ApplicationSummaryDoc[]): string[] {
        return _(applications)
            .map(s => s.formTemplateId)
            .uniq()
            .value();
    }

    public static getStartingYearList(students: Student[]): number[] {
        return _(students)
            .uniqBy(s => s.startingYear)
            .map(s => s.startingYear)
            .value();
    }

    public static getStageList(students: Student[]): number[] {
        return _(students)
            .filter(s => !!s.studentStatus?.stageId)
            .uniqBy((s: Student) => s.studentStatus.stageId)
            .map((s: Student) => s.studentStatus.stageId)
            .value();
    }

    public static getStatusList(students: Student[]): number[] {
        return _(students)
            .uniqBy(s => s.studentStatusId)
            .map(s => s.studentStatusId)
            .value();
    }

    public static getYearLevelList(students: (Student | ResearchStudent)[]): YearLevel[] {
        let yearLevels = _(students)
            .uniqBy(s => s.schoolIntakeYearId)
            .map(s => s.schoolIntakeYear)
            .value();
        const yearLevelNull = _.remove(yearLevels, yl => !yl);
        yearLevels = _.sortBy(yearLevels, s => s.sequence);
        if (yearLevelNull.length) {
            yearLevels.push({ id: 0, name: translation.unknown } as YearLevel);
        }
        return yearLevels;
    }

    public static getLegendList(legends: Legend[]): number[] {
        return legends.filter(legend => legend.isSelected).map((legend: Legend) => legend.id);
    }

    public static getSelectedLegendNames(legends: Legend[], id?: string): string[] {
        return legends.filter(legend => legend.isSelected).map((legend: Legend) => legend.name);
    }

    public static isSchoolModuleEnabled(schoolModules: ISchoolModule[], moduleName: string) {
        const schoolModule = _.find(schoolModules, m => m.name === moduleName);
        return !!(schoolModule && schoolModule.isEnabled);
    }

    public static getCurrentCampusId(campusId: number, campuses: Campus[]): number {
        const mainCampus = _.find(campuses, c => c.campusType === Campus.CAMPUS_TYPE_MAIN);
        const currentCampus = _.find(campuses, c => c.id === campusId);
        return currentCampus && currentCampus.campusType === Campus.CAMPUS_TYPE_UNDECIDED ? mainCampus.id : campusId;
    }

    public static getActualCampusIdOrAll(campusId: number | null | undefined, campuses: Campus[]): string | number {
        return !_.isNil(campusId) ? Utils.getCurrentCampusId(+campusId, campuses) : 'all';
    }

    public static getLocationByCampus(campus: Campus): string {
        const location: string[] = [];
        const address = campus.address ? `${campus.address} \n` : '';
        if (campus.city) {
            location.push(campus.city);
        }
        if ((campus.administrativeArea && campus.administrativeArea.name) || campus.postCode) {
            const statePostCode: string[] = [];
            if (campus.administrativeArea && campus.administrativeArea.name) {
                statePostCode.push(campus.administrativeArea.name);
            }
            if (campus.postCode) {
                statePostCode.push(campus.postCode);
            }
            location.push(statePostCode.join(' '));
        }
        return address + location.join(', ');
    }

    public static getNameCode(userInfoOrSisId: UserInfo | number): string {
        let sisId: number;
        switch (typeof userInfoOrSisId) {
            case 'number':
                sisId = userInfoOrSisId;
                break;
            default:
                sisId = userInfoOrSisId.managementSystemId;
        }
        return sisId === ManagementSystemCode.synergetic ? 'Synergetic Code' : 'Code';
    }

    public static toStringEncoded(params: CustomHttpParams): string {
        return params.toString().replace('+', '%2B'); // https://github.com/angular/angular/issues/18261#issuecomment-338352188
    }

    public static exportAsExcelFile(json: any[], fileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data: Blob = new Blob([excelBuffer], {
            type: Constants.ChartContextMenuOptionConstant.csvType,
        });
        saveAs(data, fileName);
    }

    public static setUnknownSchoolIntakeYearAndStartingYearId(students: Student[]): Student[] {
        return _.forEach(students, student => {
            student.schoolIntakeYearId = student.schoolIntakeYearId ?? 0;
            student.startingYear = student.startingYear ?? 0;
        });
    }

    public static async addToClipboard(url: string, mimeType = 'text/plain'): Promise<void> {
        try {
            // Safari treats user activation differently:
            // https://bugs.webkit.org/show_bug.cgi?id=222262.
            navigator.clipboard.write([
                new ClipboardItem({
                    [mimeType]: new Promise(async resolve => {
                        const data = await fetch(url);
                        const blob = await data.blob();
                        resolve(blob);
                    }) as unknown as Blob,
                }),
            ]);
        } catch {
            // Chromium
            const data = await fetch(url);
            const blob = await data.blob();
            navigator.clipboard.write([
                new ClipboardItem({
                    [blob.type]: blob,
                }),
            ]);
        }
    }

    /**
     * @param name CSS custom property name without the -- prefix.
     * @param element HTML element.
     * @returns Property value.
     */
    public static getCssProperty(name: string, element = document.body): string {
        const PREFIX = '--';
        return window.getComputedStyle(element).getPropertyValue(PREFIX + name);
    }

    /**
     * Gets the utm params from the url
     *
     * Note: It's used document.location.href, document.referrer and a lib 'utl-parse'
     *       to get the query params of parent website, when the system is rendered in an iframe
     *
     * @returns {UtmParams}
     */
    public static getUtmParameters(): UtmParams {
        const url = window.location === window.parent.location ? document.location.href : document.referrer;
        return _.pickBy(new urlParse(url, true).query, (_value, key) => _.startsWith(key, 'utm_'));
    }

    public static getPastYearsFromCurrentYear(durationYears: number): number[] {
        const years: number[] = [];
        const currentYear = new Date().getFullYear() - 1;
        const firstYear = currentYear - durationYears;
        for (let i = currentYear; i > firstYear; i--) {
            years.push(i);
        }
        return years;
    }

    public static canMerge<T>(selection: SelectionModel<T>): boolean {
        return selection.selected.length > 1 && selection.selected.length < 6;
    }

    public static addOrRemoveActionsColumn(isModal: boolean, displayedColumns: string[]): void {
        const colNameActions = 'actions';
        if (isModal && _.includes(displayedColumns, colNameActions)) {
            displayedColumns.splice(displayedColumns.length - 1, 1);
        }
        if (!isModal && !_.includes(displayedColumns, colNameActions)) {
            displayedColumns.splice(displayedColumns.length, 0, colNameActions);
        }
    }

    public static addSelectColumn(displayedColumns: string[], doAdd: boolean): void {
        const columnName = 'select';
        if (doAdd && !_.includes(displayedColumns, columnName)) {
            displayedColumns.splice(0, 0, columnName);
        }
    }

    public static calcAmount(amount: number, discountInPercent: number): number {
        const discountedAmount = parseFloat((amount - (amount * discountInPercent) / 100).toFixed(2));
        return discountedAmount < 0.5 && discountedAmount > 0 ? 0.5 : discountedAmount;
    }

    public static extractModelValue(model: { [k: string]: any }, schema: { [key: string]: any }, key: string): string {
        let modelValue;
        if (model.hasOwnProperty(key)) {
            modelValue = _.find(schema[key].oneOf, (val: any) => val.enum[0] === model[key])?.description;
        }
        return modelValue || '';
    }

    public static prepareAddFromGooglePlaceAutoComplete(
        place: any,
        formGroup: UntypedFormGroup,
        addressOptions: IAddressOptions,
        countryId: string,
        administrativeAreas: AdministrativeArea[],
        countries: Country[]
    ) {
        let addressName = '';

        let tempAdministrativeAreaId;
        _.forEach(place.address_components, component => {
            if (_.find(component.types, t => t === 'subpremise')) {
                addressName += `${component.long_name}/`;
            }
            if (_.find(component.types, t => t === 'street_number')) {
                addressName += `${component.long_name} `;
            }
            if (_.find(component.types, t => t === 'route')) {
                addressName += addressName[addressName.length - 2] === ',' ? ` ${component.long_name},` : `${component.long_name}`;
            }
            if (_.find(component.types, t => t === 'landmark')) {
                addressName +=
                    addressName[addressName.length - 2] === ',' ? ` near ${component.long_name}, ` : `, near ${component.long_name}`;
            }
            if (_.find(component.types, t => t === 'locality') || _.find(component.types, t => t === 'administrative_area_level_3')) {
                formGroup.controls['city'].setValue(component.long_name);
            }
            if (_.find(component.types, t => t === 'postal_code')) {
                formGroup.controls['postCode'].setValue(component.long_name);
            }
            if (_.find(component.types, t => t === 'sublocality')) {
                if (addressOptions.sublocality.isUsed) {
                    formGroup.controls['sublocality'].setValue(component.long_name);
                }
            }
            if (_.find(component.types, t => t === 'administrative_area_level_1')) {
                if (countryId && addressOptions.state.isUsed) {
                    formGroup.controls.administrativeAreaId.setValue(_.find(administrativeAreas, c => c.name === component.long_name)?.id);
                }
                if (!countryId) {
                    tempAdministrativeAreaId = component.long_name;
                }
            }
            if (_.find(component.types, t => t === 'country')) {
                if (!countryId) {
                    formGroup.controls.countryId.setValue(_.find(countries, c => c.name === component.long_name)?.id);
                }
            }
        });
        formGroup.controls['address'].setValue(addressName);
        return tempAdministrativeAreaId;
    }

    public static resetAddressFields(formGroup: UntypedFormGroup) {
        formGroup.controls.address.reset();
        formGroup.controls.city.reset();
        formGroup.controls.sublocality?.reset();
        formGroup.controls.administrativeAreaId?.reset();
        formGroup.controls.postCode.reset();
    }

    public static getFullName(lastName, firstName): string {
        return `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`;
    }

    /**
     * Removes the class ‘toBeRemovedClass’ from all elements in the DOM that have a class ‘targetName'
     */
    public static removeClassFromDocument(targetName: string, toBeRemovedClass: string, delayTime: number): void {
        const classList = document.getElementsByClassName(targetName);
        let className = null;
        for (const index of Object.keys(classList)) {
            if (classList[index].classList.contains(toBeRemovedClass)) {
                className = classList[index];
                // If exists, remove it so that after duration ms the target class without removed class  will appear with animated effects
                setTimeout(() => {
                    if (className) {
                        className.classList.remove(toBeRemovedClass);
                    }
                }, delayTime);
            }
        }
    }
    /**
     * Returns two elements whose sequences are swapped. If up is true, the above element's sequence would be selected to be swapped with. If up is false, the below element's sequence would would be selected to be swapped with.
     *
     * @param inputData target list from which two elements' sequence would be swapped
     * @param id  target element's id
     * @param up  true or false based on up-arrow being clicked or down-arrow being clicked
     * @returns  Swapped two elements
     */
    public static getSwappedSequenceData<T extends Sequence>(inputData: T[], id: number, up: boolean): T[] {
        const current = inputData.find(item => item.id === id);
        if (!current) return undefined;

        const target = up
            ? inputData
                  .filter(item => item.sequence < current.sequence)
                  .reduce((acc, cur) => (acc.sequence > cur.sequence ? acc : cur), inputData[0])
            : inputData
                  .filter(item => item.sequence > current.sequence)
                  .reduce((acc, cur) => (acc.sequence < cur.sequence ? acc : cur), inputData[inputData.length - 1]);

        if (!target) return undefined;

        // Deep copy of target data not to change orginal array
        const [copyCurrent, copyTarget] = [this.clone(current), this.clone(target)];
        // Now can swap data safely
        [copyCurrent.sequence, copyTarget.sequence] = [copyTarget.sequence, copyCurrent.sequence];

        return [copyCurrent, copyTarget];
    }
    /**
     * Compares if the source element's sequence is the same sequence of target element
     *
     * @param id id of target element of the provided data, whose sequence would be compared to source elemnet's
     * @param sourceData source data
     * @param targetData target data
     * @returns false or true (false means compared value is different)
     */
    public static async compareTheSequence<T>(id: number, sourceData: T[], targetData: T[]): Promise<boolean> {
        if (!sourceData || !targetData) return false;
        const targetElement = _.find(targetData, s => s['id'] === id);
        const sourceElement = _.find(sourceData, s => s['id'] === id);
        if (targetElement['sequence'] !== sourceElement['sequence']) {
            Utils.showNotification(
                'The sequence has been updated by other source. Please verify sequences and do it again if needed',
                Colors.warning
            );
            return false;
        }
        return true;
    }

    public static getLatestAppExportDate(appStudentMappings: AppStudentMapping[]): string | null {
        return _(appStudentMappings).map('application.exportDate').max() ?? null;
    }

    public static getHasFutureSiblings(hasFutureSiblings: boolean | null | undefined): string {
        return hasFutureSiblings !== null && hasFutureSiblings !== undefined
            ? hasFutureSiblings
                ? HasFutureSiblings.Yes
                : HasFutureSiblings.No
            : HasFutureSiblings.Unknown;
    }

    public static getStudentStatusesAndStage(
        allStudentStatuses: StudentStatus[],
        stages: ListItem[],
        declinedIds: number[],
        studentStatusId: number
    ): { studentStatuses: StudentStatus[]; stage: ListItem } {
        let studentStatuses: StudentStatus[] = [];
        let stage: ListItem;
        const studentStatus = allStudentStatuses.find(s => s.id === studentStatusId);
        if (studentStatus) {
            stage = stages.find(s => s.id === studentStatus.stageId);
            if (stage) {
                studentStatuses =
                    stage.code === LICode.stage_declined
                        ? allStudentStatuses.filter(s => declinedIds.includes(s.id))
                        : allStudentStatuses.filter(s => !declinedIds.includes(s.id));
            }
        }
        return { studentStatuses, stage };
    }
}

export class Colors {
    public static danger = 'danger';
    public static success = 'success';
    public static info = 'info';
    public static warning = 'warning';
    public static rose = 'rose';
    public static primary = 'primary';
}

export interface CalculationOptions {
    hasAlumni: string;
    siblingsId: number;
    religionId: number;
    currentSchool: CurrentSchool;
    leadScores: LeadScore[];
    boardingTypeId: number;
    otherInterests: number[];
    familyConnection?: number;
    hasFutureSiblings?: string;
    genderId?: number;
    additionalNeedsId?: number[];
}

export function joinNames(key: string) {
    return row => row[key]?.map(c => c.name)?.join('◬') || '';
}

export function generateMatFilterPredicate(
    displayColumns: string[],
    fieldHandlers: {
        [key: string]: (data: unknown, fieldName?: string) => string;
    } = {}
) {
    return (data: unknown, filter: string) => {
        const values = [];
        displayColumns.forEach(fieldName => {
            const value = fieldHandlers[fieldName]?.(data) ?? _.get(data, fieldName);
            if (value) {
                values.push(_.toLower(value));
            }
        });
        const transformedFilter = filter.trim().toLowerCase();
        return values.find(i => _.includes(i, transformedFilter));
    };
}

export function getUserInfoFromTokenOptional(): UserInfo | null {
    let userInfo: UserInfo | null;
    try {
        userInfo = Utils.getUserInfoFromToken();
    } catch (error) {
        userInfo = null;
    }
    return userInfo;
}
